<template>
    <div
        class="form-row"
        :class="{
            'form-row--no-margin': props.noMargin,
            'form-row--no-collapse': props.noCollapse,
        }"
    >
        <slot />
    </div>
</template>

<script setup lang="ts">
    export interface Props {
        noMargin?: boolean;
        noCollapse?: boolean;
    }

    const props = withDefaults(defineProps<Props>(), {
        noMargin: false,
        noCollapse: false,
    });
</script>

<style lang="postcss" scoped>
    .form-row {
        @apply flex flex-wrap -mx-2 max-sm:block;

        &--no-collapse {
            @apply max-sm:flex;
        }

        &:last-child,
        &--no-margin {
            @apply -mb-4;
        }
    }
</style>
